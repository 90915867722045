
import React from 'react'
import { Event, Banners } from '../pages/index'
import { News } from '../pages/news'
import { Magazine } from '../pages/magazine/[id]'
import { EventCardList } from './EventCardList'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { NewsCardList } from '../components/NewsCardList'
import { PickupCategoryLabel } from '../components/PickupCategoryLabel'
import { EventStatusIcon } from '../components/EventStatusIcon'
import { GlobalNav } from '../components/GlobalNav'
import dayjs from 'dayjs'
import Slider, { ResponsiveObject, Settings } from "react-slick";

interface TopProps {
  events: Event[]
  newsList: News[]
  pickupList: Magazine[]
  banners: Banners[]
}

const Top: React.FC<TopProps> = ({ events, newsList, pickupList, banners }: TopProps) => {

  const settingsHero = {
    autoplay: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    fade: true,
    arrows: false,
    autoplaySpeed: 5000,
    dotsClass: 'c-hero__list-dots',
    customPaging: (i: number) => {
      return <div className="c-hero__list-dots-btn"></div>
    }
  };

  const settingPickupResponsive: Settings = {
    className: 'c-page-home__pickup-list',
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    // @ts-ignore
    prevArrow: <button class="c-page-home__pickup-list-arrow" data-type="prev"></button>,
    // @ts-ignore
    nextArrow: <button class="c-page-home__pickup-list-arrow" data-type="next"></button>,
  }

  const settingsPickup: Settings = {
    className: 'c-page-home__pickup-list',
    slide: "ul",
    responsive: [
      {
        // mobileFirstのオプションがないため10000で代用
        breakpoint: 10000,
        settings: "unslick"
      },
      {
        breakpoint: 767,
        settings: settingPickupResponsive,
      }]
  }

  const settingEventResponsive: Settings = {
    className: 'c-page-home__event-list',
    infinite: true,
    arrows: true,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 5000,
    // @ts-ignore
    prevArrow: <button class="c-page-home__event-list-arrow" data-type="prev"></button>,
    // @ts-ignore
    nextArrow: <button class="c-page-home__event-list-arrow" data-type="next"></button>,
  }

  const settingsEvent: Settings = {
    className: 'c-page-home__event-list',
    slide: "ul",
    responsive: [
      {
        // mobileFirstのオプションがないため10000で代用
        breakpoint: 10000,
        settings: "unslick"
      },
      {
        breakpoint: 767,
        settings: settingEventResponsive,
      }]
  }

  return (
    <div>
      <noscript>
        &lt;style&gt;
        body {'{'}
        visibility: visible;
        {'}'}
        &lt;/style&gt;
      </noscript>
      <div className="c-page-home__base">
        <div className="c-page-home__header">
          <Header />
        </div>
        <div className="c-page-home__globalnav">
          <GlobalNav />
        </div>
        <div className="c-page-home__hero">
          <div className="c-hero">
            <Slider {...settingsHero}>
              {/* <li className="c-hero__list-item" data-item="04">
                <a href="https://betsudai.co.jp/event_lifemarche2023" target="_blank">
                  <picture>
                    <source srcSet="/img/slider_sp.png" media="(max-width: 767px)" />
                    <img src="/img/slider_pc.png" alt="" />
                  </picture>
                </a>
              </li> */}
              {/* <li className="c-hero__list-item" data-item="04">
                <a href="https://betsudai.co.jp/your_life/">
                  <picture>
                    <source srcSet="/img/top_sp_06.jpg" media="(max-width: 767px)" />
                    <img src="/img/top_pc_06.jpg" alt="" />
                  </picture>
                </a>
              </li> */}
              <li className="c-hero__list-item" data-item="04">
                <a href="https://betsudai.co.jp/lp/betsudai_cm/index.html">
                  <picture>
                    <source srcSet="/img/top_sp_04.jpeg" media="(max-width: 767px)" />
                    <img src="/img/top_pc_04.jpeg" alt="" />
                  </picture>
                </a>
              </li>
              <li className="c-hero__list-item" data-item="05">
                <a href="https://betsudai.co.jp/hojyokin2024">
                  <picture>
                    <source srcSet="/img/hojyokin2024/hojyokin2024slide_sp.png" media="(max-width: 767px)" />
                    <img src="/img/hojyokin2024/hojyokin2024slide_pc.png" alt="" />
                  </picture>
                </a>
              </li>
              {/* <li className="c-hero__list-item" data-item="01">
                <a href="/company/">
                  <picture>
                    <source srcSet="/img/top_sp_01.jpg" media="(max-width: 767px)" />
                    <img src="/img/top_pc_01.jpg" alt="ALL for your LIFE マイホームはマイライフ。ベツダイについて詳しくはこちら" />
                  </picture>
                </a>
              </li> */}
              {/* 大分移住プロジェクト */}
              <li className="c-hero__list-item" data-item="02"><a href="/oitanavi/">
                <picture>
                  <source srcSet="/img/hero-sp202504.png" media="(max-width: 767px)" />
                  <img src="/img/hero-pc202504.png" alt="" />
                </picture>
              </a>
              </li>
              <li className="c-hero__list-item" data-item="03"><a target="_blank" href="https://arwrk.net/recruit/hhc5p8u69gcdhd1">
                <picture>
                  <source srcSet="/img/bnr_saiyou02_sp.png" media="(max-width: 767px)" />
                  <img src="/img/bnr_saiyou02_pc.png" alt="" />
                </picture>
              </a>
              </li>
            </Slider>
          </div>
        </div>
        <div className="c-page-home__main">
          <main>
            {/* <section className="c-page-home__banner c-page-home__section">
              <div className="message551">
                <div className="title">システムメンテナンスのお知らせ（2024年2月14日（水））</div>
<p>
日頃よりベツダイサイトをご利用いただき、誠にありがとうございます。<br/>
以下の日程でシステムメンテナンスを実施いたします。<br/>
メンテナンス期間中はサイトへのアクセス及び問い合わせがご利用いただけない状態となりますので、予めご了承くださいませ。<br/>
<br/>
メンテナンス期間：2024年2月14日（水） 22:00 ~ 2024年2月14日（木） 24:00<br/>
<br/>
ご不便をおかけいたしますが、ご理解いただけますようよろしくお願いいたします。
</p>
              </div>

            </section> */}
            {/* <section className="c-page-home__banner c-page-home__section">
              <div className="c-page-home__section-inner">
              <a target='_blnak' href="https://shop.betsudai.jp/">
                <picture>
                  <source srcSet="/img/starmarche_banner.png" media="(max-width: 767px)" />
                  <img src="/img/starmarche_banner.png" className="banner" alt="ベツダイスターマルシェ" />
                </picture>
                </a>
              </div>

            </section> */}
            <section className="c-page-home__news c-page-home__section">
              <div className="c-page-home__section-inner">
                <div className="c-page-home__section-title">
                  <h2>NEWS<span>新着情報</span></h2>
                  <NewsCardList newsList={newsList} />
                  <p className="c-page-home__section-button"><a href="/news/">もっと見る</a></p>
                </div>
              </div>
            </section>
            {/* <section className="c-page-home__banner_pc c-page-home__section">
              <div className="c-page-home__section-inner">
                <a href="/betsudai_radio/">
                  <img src="/img/betsudai_radio_pc.png" className="banner" alt="ベツダイラジオミーティング" />
                </a>
              </div>
              <div className="c-page-home__section-inner">
                <a href="/kasugauraDIY/">
                  <img src="/img/kasugaura/banner.png" className="banner" alt="春日浦DIYコラボ商品" />
                </a>
              </div>
            </section> */}
            <section className="c-page-home__pickup c-page-home__section">
              <div className="c-page-home__section-inner">
                <div className="c-page-home__section-title">
                  <h2>PICK UP<span>特集</span></h2>

                  <Slider {...settingsPickup}>
                    {pickupList.map((magazine, i) => (
                      <li key={i} >
                        <a className="c-page-home__pickup-item" href={`/magazine/${magazine.id}`}>
                          <div className="c-page-home__pickup-item-thumb"><img src={magazine.eyeCatchImage} alt="" /></div>
                          {magazine.flagNew && (
                            <span data-type="label" data-label="new">NEW</span>
                          )}
                          <p data-type="date">{magazine.publishedAt}</p>
                          <div className="c-page-home__pickup-item-detail">
                            <p data-type="article">{magazine.title}</p>
                          </div>
                          <PickupCategoryLabel category={magazine.baseSite} />
                        </a>
                      </li>
                    ))}
                  </Slider>
                  <p className="c-page-home__section-button" data-type="pickup"><a href="/pickup/">もっと見る</a></p>
                </div>
              </div>
            </section>
            <section className="c-page-home__service c-page-home__section" id="service">
              <div className="c-page-home__section-inner">
                <div className="c-page-home__section-title">
                  <h2>SERVICE<span>サービス一覧</span></h2>
                  <ul className="c-page-home__service-list">
                    <li className="c-page-home__service-item" data-list="buy">
                      <a href="/buy/"> <h3>家を、売る・買う</h3></a>
                      <p>大分・福岡で不動産の売却をお考えの方、<br />建売戸建、マンション、土地をお探しの方に。</p>
                      <div className="c-page-home__service-detail">
                        <ul data-level="01">
                          <li>
                            <a href="/buy/"> <h4>売 る</h4></a>
                            <ul data-level="02">
                              <li><a href="/buy/#buy01">不動産売却</a></li>
                            </ul>
                          </li>
                        </ul>
                        <ul data-level="01">
                          <li>
                            <a href="/buy/"> <h4>買 う</h4></a>
                            <ul data-level="02">
                              <li><a href="/buy/#buy02">新築戸建</a></li>
                              <li><a href="/buy/#buy03">土地</a></li>
                              <li><a href="/buy/#buy04">新築マンション</a></li>
                              <li>
                                <a href="/buy/#buy05">中古戸建・<br data-type="pc" />中古マンション</a>
                              </li>
                              <li><a href="/buy/#buy06">収益物件</a></li>
                              <li><a href="/buy/#buy07">リゾート物件</a></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="c-page-home__service-item" data-list="rent">
                      <a href="/rent/"> <h3>家を、貸す・借りる</h3></a>
                      <p>大分市中心部・周辺部、別府市で住まいを<br />貸したい方、借りたい方へ。</p>
                      <div className="c-page-home__service-detail">
                        <ul data-level="01">
                          <li>
                            <a href="/rent/"> <h4>貸 す</h4></a>
                            <ul data-level="02">
                              <li><a href="/rent/#rent01">賃貸管理</a></li>
                            </ul>
                          </li>
                        </ul>
                        <ul data-level="01">
                          <li>
                            <a href="/rent/"> <h4>借りる</h4></a>
                            <ul data-level="02">
                              <li><a href="/rent/#rent02">賃貸</a></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="c-page-home__service-item" data-list="build">
                      <a href="/build/"> <h3>家を、建てる・リフォーム</h3></a>
                      <p>大分、福岡、愛知の住まいづくりはお任せください。<br />創業50年の実績で、理想の暮らしを実現します。</p>
                      <div className="c-page-home__service-detail">
                        <ul data-level="01">
                          <li>
                            <a href="/build/"> <h4>建てる</h4></a>
                            <ul data-level="02">
                              <li><a href="/build/#build01">新築戸建/規格・注文</a></li>
                              <li><a href="/build/#build02">リノベーション</a></li>
                            </ul>
                          </li>
                        </ul>
                        <ul data-level="01">
                          <li>
                            <a href="/build/"> <h4>リフォーム</h4></a>
                            <ul data-level="02">
                              <li><a href="/build/#build03">リフォーム</a></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="c-page-home__service-item" data-list="afterservice">
                      <a href="/afterservice/"> <h3>家を、守る</h3></a>
                      <p>住まいや暮らしをよりよく楽しく快適にするために。<br />お役にたてる情報やサービスを提供しています。</p>
                      <div className="c-page-home__service-detail">
                        <ul data-level="01">
                          <li>
                            <a href="/afterservice/"> <h4>守 る</h4></a>
                            <ul data-level="02">
                              <li><a href="/afterservice/#afterservice01">お客さまサポート</a></li>
                              <li><a href="/afterservice/#afterservice02">マンション管理</a></li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <section className="c-page-home__event c-page-home__section">
              <div className="c-page-home__section-inner">
                <div className="c-page-home__section-title">
                  <h2>EVENT<span>イベント</span></h2>
                </div>
                <Slider {...settingsEvent}>
                  {events.map((event, i) => (
                    <li key={i}>
                      <a className="c-page-home__event-item" href={event.url} target="_blank">
                        <span data-type="label">
                          <EventStatusIcon holdingStatus={event.holdingStatus} />
                        </span>
                        <div className="c-page-home__event-item-thumb">
                          <img src={event.pictureImageUrl} />
                        </div>
                        <div className="c-page-home__event-item-detail">
                          <span data-type="branch">{event.category}</span>
                          <p data-type="article">{event.name}</p>
                          <p data-type="info">
                            期間:{event.term}
                            <br />
                            場所:{event.place}
                          </p>
                          {event.hasGift && (
                            <div data-type="gift">
                              <img src="img/icon-event-present.png" />
                            </div>
                          )}
                          {event.eventTags.map((tag, index) => (
                            <span key={index} data-type="category">{tag.name}</span>
                          ))}
                        </div>
                      </a>
                    </li>
                  ))}
                </Slider>
                {events.length == 0 ? (
                  <p className="text-center">現在イベントはありません</p>
                ) : (
                  <p className="c-page-home__section-button" data-type="event"><a href="/event/">もっと見る</a></p>
                )}
              </div>
            </section>

            <section className="c-page-home__support c-page-home__section">
              <div className="c-page-home__section-inner">
                <div className="c-page-home__section-title">
                  <h2>SUPPORT<span>お客さまサポート</span></h2>
                </div>
                <div className="c-page-home__support-block">
                  <p className="c-page-home__support-copy">いつまでも住み心地良くあり続けるように、住まいや暮らしを楽しく快適にする情報を提供します。</p>
                  <div className="c-page-home__support-bnr">
                    <a href="https://betsudai.co.jp/plus/" target="_blank">
                      <picture>
                        <source srcSet="/img/bnr-support-sp.png" media="(max-width: 767px)" />
                        <img src="/img/bnr-support-pc.png" alt="BETSUDAI+" />
                      </picture>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="c-page-home__bnr c-page-home__section">
              <div className="c-page-home__section-inner">
                <ul className="c-page-home__bnr-list">
                  {banners.map((banner, i) => (
                    <li key={i} className="c-page-home__bnr-item"><a className="c-page-home__bnr-item-block" href={banner.url}><img src={banner.bannerMedium} alt={banner.title} /></a></li>
                  ))}
                </ul>
              </div>
            </section>
          </main>
        </div>
        <div className="c-page-sub__footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Top
